import React, { Component } from 'react';
import './App.css';
import Modal from './components/modal/Modal';
import './setup';
import formExtension from './extensions/form';
// import d3Extension from './extensions/d3';
import presExtension from './extensions/pres';
import slideExtension from './extensions/slide';
import youtubeExtension from './extensions/youtube';
import htmlExtension from './extensions/html';
import markdownExtension from './extensions/markdown';
import modalExtension from './extensions/modal';
import debuggerformExtension from './extensions/debuggerform';
import copyExtension from './extensions/copy';
import editorExtension from './extensions/editor';
import buttonExtension from './extensions/button';
import abiformExtension from './extensions/abiform.js';
import solcExtension from './extensions/solc/index.js';
import Storage from './utils/storage.js';
import DEFAULT_WORKBOOK from './components/workbook/fixtures';
import { DEFAULT_CELL, recursiveMatchCellKeys, id2indexes, indexes2id, cellid, key2cell, id2cell } from './components/cell/utils';
import { prepCells, executeCells } from './components/workbook/utils';
import Workbook from './components/workbook/Workbook';
import * as d3require from "d3-require";
import { uniqueid, tryLoadExample } from './utils/utils.js';
import { export2Sheet, import2prov, export2raw } from './utils/sheet';

import driveapi, {tryLoadGD, uploadFileGD} from './extensions/gdrive/index';

import mal from './mal/stepA_mal';
import malTypes from './mal/types';
import interop from './mal/interop';

import {ExportBtn, GdriveBtn, ImportBtn, InfoBtn, VersionsBtn} from './utils/icons';

import {ewasmjsvm} from 'ewasm-jsvm';

window.ewasmjsvm = ewasmjsvm;
window.PROV.mal = mal;
window.PROV.mal.interop = interop;
window.PROV.utils = {cellid, id2indexes, indexes2id, key2cell, id2cell};

const _extensions = {};
mal.env_extension = {react: {}, wasm: {}};

// strip version - e.g. name@1.0.19
const packagename = name => {
  let v = name.replace(/-/g, '_').split('@');
  if (v.length === 1) v = v[0];
  else v = v[v.length - 2];
  v = v.split('/');
  return v[v.length - 1];
}

const tryreq = async (req, name) => {
  let instance;
  try {
      instance = await req(name);
  } catch(e) {
    console.log(e);
  }
  return instance;
}

const istay = value => value.charAt(0) === '(' && value.charAt(value.length - 1) === ')';
const isD3 = value => value.match(/\sd3./);
const getFormulaType = value => istay(value) ? 'taylor' : (isD3(value) ? 'd3' : 'js');

window.d3require = d3require;
let require1, require2, require3;
(async () => {
  require1 = window.require1 = await d3require.requireFrom(async name => {
    return `https://bundle.run/${name}`;
  });

  require2 = window.require2 = await d3require.requireFrom(async name => {
    return `https://unpkg.com/${name}`;
  });

  require3 = window.require3 = await d3require.requireFrom(async name => {
    return `https://cdn.jsdelivr.net/npm/${name}`;
  });
})();

window.PROV.require = async function(name, alias) {
  name = await name;
  alias = alias === 'nil' ? null : alias;
  const displayName = packagename(name);

  if (window.PROV.packages[displayName]) {
    return window.PROV.packages[displayName];
  }
  if (alias && window.PROV.packages[alias]) {
    return window.PROV.packages[alias];
  }

  let instance;
  instance = await tryreq(d3require.require, name);
  if (!instance) instance = await tryreq(require1, name);
  if (!instance) instance = await tryreq(require2, name);
  if (!instance) instance = await tryreq(require3, name);

  if (!instance && name.startsWith("http")) {
    const urlfetch = await d3require.requireFrom(async n => name);
    instance = await tryreq(urlfetch, name);
  }

  if (!instance) {
    throw new Error('Npm module not loaded: ' + name);
  }
  window.PROV.packages[displayName] = instance;
  const dn = await window.require.resolve(displayName);
  window.require.cache[dn] = instance;
  if (alias) {
    window.PROV.packages[alias] = instance;
    const da = await window.require.resolve(alias);
    window.require.cache[da] = instance;
  }

  console.log('instance', instance);
  return instance;
}

window.require = window.PROV.require;
Object.keys(d3require.require).forEach(key => {
  window.require[key] = d3require.require[key];
});
window.require.cache = {};

window.PROV.fetch = async function(url) {
  return fetch(url);
}

window.PROV.wasm = async function(url) {
  console.log('url', url);
  if (mal.env_extension.wasm[url]) return mal.env_extension.wasm[url];

  const instance = await fetch(url)
    .then(response => response.arrayBuffer())
    .then(buffer => {
      return WebAssembly.instantiate(buffer, {
        env: {abort: () => console.log('abort')}
      });
    });

  mal.env_extension.wasm[url] = instance;
  return instance;
}

mal.re = expr => mal.EVAL(mal.READ(expr), mal.repl_env);
mal.def = (key, value) => {
  mal.repl_env.set(malTypes._symbol(key), value);
}
mal.load = key => mal.repl_env.get(malTypes._symbol(key));
const modifyEnv = (name, func) => {
  const orig_func =  mal.repl_env.get(malTypes._symbol(name));
  mal.repl_env.set(malTypes._symbol(name), (...args) => {
      return func(orig_func, ...args);
  })
}

const executeAndReturn = async (id, formula) => {
  return mal.re(`(def! ${id} ${formula})`).then(() => mal.re(`${id}`));
}

window.PROV.utils.getComponent = info => {
  return mal.env_extension[info.type][info.key];
}

modifyEnv('js-eval', async (orig_func, str) => {
  const nil = null;  // eslint-disable-line no-unused-vars
  let answ;
  const extensions = _extensions;  // eslint-disable-line no-unused-vars

  try {
      answ = eval(str.toString());  // eslint-disable-line no-eval
      if (answ instanceof Promise) {
        answ = await answ.catch(e => {
          throw e;
        });
      }
      else answ = await answ;
  } catch(e) {
      console.log(`Expression: ${str}  ;;`, e);
      // answ = undefined;
      answ = e instanceof Error ? e.message : e;
  }

  if (typeof answ === 'object' && answ.type) {
    const obj = {...answ};
    obj.key = obj.key || obj.cellid || uniqueid();
    mal.env_extension[obj.type][obj.key] = obj;
    answ = {key: obj.key, type: obj.type};
  }

  return interop.js_to_mal(answ);
});

modifyEnv('get', (orig_func, obj, ...args) => {
  let newobj = obj;
  args.forEach(key => {
    newobj = newobj[key];
  });
  return interop.js_to_mal(newobj);
});

const extendMal = async (mal) => {
  await mal.re(`(def! weave (fn* (key) (js-eval (str "window.PROV.weave('" key "')"))))`);
  await mal.re(`(def! source (fn* (sheetId key) (js-eval (str "window.PROV.source(" (pr-str sheetId) "," (pr-str key) ")"))))`);
  await mal.re(`(def! encodeURI (fn* (code) (js-eval (str "encodeURI(" (pr-str code) ")" ) )))`);
  await mal.re(`(def! encodeURIComponent (fn* (code) (js-eval (str "encodeURIComponent(" (pr-str code) ")" ) )))`);
  await mal.re(`(def! require (fn* (name alias) (js-eval (str "window.PROV.require('" name "','" alias "')")) ))`);

  window.uniqueid = uniqueid;
  await mal.re(`(def! uniqueid (fn* () (js-eval "uniqueid()") ))`)

  async function mal2js(malval) {
    const value = await mal.re(malval);
    return JSON.stringify(value);
  }
  window.mal2js = mal2js;
  await mal.re(`(def! mal2js-str (fn* (malval)
    (js-eval (str
      "window.mal2js(\`"
        (pr-str malval)
      "\`)"
    ))
  ))`)

  await mal.re(`(def! fetch (fn* (url) (js-eval (str "window.PROV.fetch('" url "')")) ))`);

  await mal.re(`(def! wasm (fn* (url) (js-eval (str "window.PROV.wasm('" url "')")) ))`);
}

const extendM = async ({name, formula, callback, prereq=[]}) => {
  prereq.forEach(preq => mal.re(`(def! ${preq.name} ${preq.formula})`))
  await mal.re(`(def! _${name} ${formula})`);
  _extensions[name] = callback;
}

const extendMm = async () => {
  extendM(formExtension);
  extendM(youtubeExtension);
  extendM(markdownExtension);
  extendM(htmlExtension);
  extendM(presExtension);
  extendM(slideExtension);
  extendM(modalExtension);
  extendM(debuggerformExtension);
  extendM(copyExtension);
  extendM(solcExtension);
  extendM(editorExtension);
  extendM(buttonExtension);
  extendM(abiformExtension);
}

// const extendUI = async ({name, formula, callback }) => {
//   window.PROV.ui[name] = callback(mal);
//   return mal.re(`(def! _${name} ${formula})`);
// }

// extendUI(d3Extension);

// TODO shallow clone
const clone = value => {
  let copy
  try {
    copy = JSON.parse(JSON.stringify(value));
  } catch(e) {
    if (value instanceof Array) copy = [...value];
    else if (value instanceof Object) copy = {...value};
    else copy = value;
  }
  return copy;
}

function executeJs(id, code) {
  const packageNames = Object.keys(window.PROV.packages);
  const destruct = 'const {' + packageNames.join(', ') + ' } = window.PROV.packages;';
  code = code.trim();
  // const returncode = code.includes('return') ? code : `return ${code}`;

  const returncode = code.slice(0, 6) === 'return' ? code : `return ${code}`;

  const wcode = `window.PROV.js["${id}"] = async function () {
      const PARENT_ID = "${id}_output";
      const MODAL_ID = "${id}_modal";
      ${destruct}
      ${returncode}
  }`

  return new Promise(async (resolve, reject) => {
    try {
      await eval(wcode) // eslint-disable-line no-eval
    } catch(e) {
      reject(e);
    }

    try {
      const result = await window.PROV.js[id]();
      resolve(result);
    } catch(e) {
      reject(e);
    }
  });
}

const prep2save = (sheets) => {
  return sheets.map(sheet => {
    return sheet.map(col => {
      return col.map(cell => {
        try {
          JSON.stringify(cell.view);
          return cell;
        } catch(e) {
          return {...cell, view: ''};
        }
      })
    })
  })
}

const saveLocalStorage = (sheets, settings) => {
  Storage.set('notebook', {sheets: prep2save(sheets), settings});
}

const EMPTY_SHEETS = [[[{formula: '', view: '', type: '', row: 0, col: 0, sheetId: 0, id: '0_0_0'}]]];

const errorMessage = error => `!!!: ${error.message}
  ${error.stack};
` ;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
        sheets: EMPTY_SHEETS,
        settings: {},
        dependencies: {},
        activeSheetId: 0,
        modals: [],
        modalSize: {width: 1300, height: 400},
        google: {signedin: false},
    }

    this.MAXITER = 1000;
    this.order = null;
  }

  loadDefaultExample() {
    let saved = Storage.get('notebook');
    if (!saved) saved = {sheets : prepCells(DEFAULT_WORKBOOK.sheets)};
    return saved;
  }

  async tryLoadExample() {
    const filebuffer = await tryLoadExample(window.location.href);
    if(!filebuffer) return;
    const sheets = import2prov(filebuffer);
    console.log('sheets', sheets);
    return {sheets};
  }

  async tryLoadGD() {
    const filebuffer = await tryLoadGD(window.location.href);
    if(!filebuffer) return;
    const sheets = import2prov(filebuffer);
    console.log('sheets', sheets);
    return {sheets};
  }

  async onDirtyCellChange(newcell) {
    let {sheets} = this.state;
    const { row, col, sheetId } = newcell;
    const cell = { ...sheets[sheetId][col][row], ...newcell };
    sheets[sheetId][col][row] = cell;

    mal.def(cell.id, cell.dirtyview);

    this.setState({sheets});

    // Rexecute all cells that are _after_ the changed cell in the global execution order
    let cellsToExecute = [];
    if (this.order) {
      const index = this.getCellFlatIndex(cell, sheets);
      const orderedCellIndex = this.order.findIndex(val => val === index);
      cellsToExecute = this.order.slice(orderedCellIndex + 1);
    }
    this.executeCells(sheets, cellsToExecute);

    saveLocalStorage(sheets, this.state.settings);
  }

  async onChangeCellContent(newcell, finishedEditing) {
    let {sheets} = this.state;
    const { row, col, sheetId } = newcell;
    const cell = { ...sheets[sheetId][col][row], ...newcell };
    delete cell.dirtyview;

    sheets[sheetId][col][row] = cell;
    const ans = await this.executeCell(cell, sheets);
    sheets[sheetId][col][row].view = ans.response;

    if (cell.formula.length > 0 && (sheets[sheetId][col].length <= row + 1)) {
      ({sheets} = this.addCellAt(sheetId, row + 1, col, sheets));
    }
    if (finishedEditing && cell.formula.length === 0 && row !== (sheets[sheetId][col].length - 1)) {
      ({sheets} = this.removeCellAt(sheetId, row, col, sheets));
    }

    this.setState({sheets});

    // Rexecute all cells that are after the changed cell in the global execution order
    if(finishedEditing) {
      let cellsToExecute = [];
      if (this.order) {
        const index = this.getCellFlatIndex(cell, sheets);
        const orderedCellIndex = this.order.findIndex(val => val === index);
        cellsToExecute = this.order.slice(orderedCellIndex);
      }
      this.executeCells(sheets, cellsToExecute);
    }

    saveLocalStorage(sheets, this.state.settings);
  }

  getCellFlatIndex(cell, sheets) {
    const {sheetId, row, col} = cell;
    let index = 0;
    // all previous sheets
    sheets.slice(0, sheetId).forEach(sheet => {
      sheet.forEach(col => {
        index += col.length;
      })
    });
    // all previous columns from same sheet
    sheets[sheetId].slice(0, col).forEach(col => index += col.length);
    // all previous rows from same column
    index += row;
    return index;
  }

  replaceCellKeys(formula, cell) {
    return recursiveMatchCellKeys(formula, (row, col, sheetId) => {
      sheetId = sheetId || cell.sheetId;
      cell.formulatype = cell.formulatype || getFormulaType(cell.formula);

      const id = cellid({row, col, sheetId});

      if (cell.formulatype === 'js') {
        return `window.PROV.mal.load("${id}")`;
      }

      return id;
    });
  }

  async executeCell(cell, sheets) {
    let value = cell.formula;
    if (!value) return {};
    let response = "", error = null;

    cell.formulatype = getFormulaType(value);
    cell.id = cell.id || cellid(cell);

    value = this.replaceCellKeys(value, cell);

    if (cell.formulatype === 'taylor') {
      // injecting the cellid as first argument, for extensions
      Object.keys(_extensions).forEach(name => {
        const regex = new RegExp(name + '\\W', 'g');
        value = value.replace(regex, `_${name} "${cell.id}" `);
      });
      try {
        response = await executeAndReturn(cell.id, value);
      } catch(e) {
        response = errorMessage(e);
        error = e;
      }
    }
    else if (cell.formulatype === 'd3') {
      response = 'Run this d3 code with: `(dd3 (weave "' + indexes2id(cell.row, cell.col) + '"))`';
    }
    else {
      try {
        response = await executeJs(cell.id, value);
      } catch (e) {
        response = errorMessage(e);
        error = e;
      }
      mal.def(cell.id, response);
    }

    return {response, error, formulatype: cell.formulatype};
  }

  async executeCells(sheets, cellsToExecute) {
    sheets = clone(sheets);

    if (cellsToExecute) {
      // eslint-disable-next-line no-unused-vars
      const [newsheets, order, notexecuted] = await executeCells(
        sheets,
        this.executeCell.bind(this),
        this.MAXITER,
        cellsToExecute,
      );

      if (notexecuted.length === 0) {
        sheets = newsheets;
      } else {
        this.order = null;
        cellsToExecute = null;
      }
    }

    if (!cellsToExecute) {
      const [newsheets, order] = await executeCells(
        sheets,
        this.executeCell.bind(this),
        this.MAXITER
      );
      sheets = newsheets;
      this.order = order;
    }

    this.setState({ sheets });
    saveLocalStorage(sheets, this.state.settings);
  }

  removeCellAt(sheetId, row, col, sheets) {
    let save = false;
    if (!sheets) {
      sheets = this.state.sheets;
      save = true;
    }

    const index = this.getCellFlatIndex(sheets[sheetId][col][row], sheets);

    sheets[sheetId][col].splice(row, 1);

    // Update row numbers from row -> end
    for (let i = row; i < sheets[sheetId][col].length; i++) {
      sheets[sheetId][col][i].row -= 1;
      sheets[sheetId][col][i].id = cellid(sheets[sheetId][col][i]);
      if (sheets[sheetId][col][i].view && sheets[sheetId][col][i].key) {
        sheets[sheetId][col][i].view.key = sheets[sheetId][col][i].id;
      }
    }

    if (save) {
      this.setState({sheets});
    }

    if (this.order) {
      const orderedIndex = this.order.findIndex(val => val === index);
      this.order = this.order.map(ind => {
        if (ind > index) return ind - 1;
        return ind;
      })
      this.order.splice(orderedIndex, 1);
    }
    return {sheets};
  }

  addCellAt(sheetId, row, col, sheets) {
    let save = false;
    if (!sheets) {
      sheets = this.state.sheets;
      save = true;
    }

    const newcell = {
      ...DEFAULT_CELL,
      sheetId,
      row,
      col,
    }
    newcell.id = cellid(newcell);
    sheets[sheetId][col].splice(row, 0, newcell);

    // Update row numbers from row -> end
    for (let i = row + 1; i < sheets[sheetId][col].length; i++) {
      sheets[sheetId][col][i].row += 1;
      sheets[sheetId][col][i].id = cellid(sheets[sheetId][col][i]);
      if (sheets[sheetId][col][i].view && sheets[sheetId][col][i].key) {
        sheets[sheetId][col][i].view.key = sheets[sheetId][col][i].id;
      }
    }

    // saving occurs when cell is added in the middle, not at the end
    // -> we don't need to return dependencies for adding a new cell at the end
    if (save) {
      this.setState({sheets});
    }

    if (this.order) {
      // Add this cell at the end of the execution order
      const index = this.getCellFlatIndex(newcell, sheets);
      this.order = this.order.map(ind => {
        if (ind >= index) return ind + 1;
        return ind;
      })
      this.order.push(index);
    }
    return {sheets};
  }

  // e.g. ["0_0", "0_2", "0_1", "0_3"]
  onRowOrderChange(order) {
    // console.log('onRowOrderChange', order);
    // const keys = order.map(key => key.split('_').map(parseInt));
    // const col = keys[0][0];
    // const rows = keys.map(val => val[1]);
    // const movedRow = rows.find((val, i) => )
  }

  openModal(cellid) {
    const modalid = cellid + '_modal';
    const {modals} = this.state;
    const modal = modals.find(item => item.id === modalid);
    modal.open = true;
    this.setState({modals});
  }

  createModal(cellid, content) {
    const {modals} = this.state;
    const modalid = cellid + '_modal';
    const modalinst = {
      id: modalid,
      content: window.PROV.utils.getComponent(content),
      open: false,
      ref: React.createRef(),
    }

    const index = modals.findIndex(modal => modal.id === modalid);
    if (index < 0) modals.push(modalinst);
    else modals.splice(index, 1, modalinst);
    this.setState({modals});
  }

  async componentDidMount () {
    await extendMal(mal);
    await extendMm(mal);
    const self = this;

    // TODO fix weave
    const weave = (sheetId, ri, ci) => {
      sheetId = sheetId || self.state.activeSheetId;
      const value = self.state.sheets[sheetId][ci][ri];
      const result = self.replaceCellKeys(value.formula, value, self.state.sheets);
      return result;
    }
    window.PROV.weave = (key, sheetId) => {
      const [ri, ci] = id2indexes(key);
      return weave(sheetId, ri, ci);
    }

    // Only if put last
    window.PROV.source = (sheetId, key) => {
      let code = [], ri, ci;
      if (key) ([ri, ci] = id2indexes(key));

      const flattened = sheets.flat().flat();
      (this.order || []).forEach(i => {
        if (flattened[i].formula.includes('source') || flattened[i].formula.includes('weave')) return;
        if (sheetId || sheetId === 0) {
          if (flattened[i].sheetId !== sheetId) return;
          if (key && (flattened[i].row !== ri || flattened[i].col !== ci)) return;
        }

        let weaved = weave(flattened[i].sheetId, flattened[i].row, flattened[i].col);
        // eslint-disable-next-line no-useless-escape
        weaved = weaved.replace(/\"/g, '\\"');  // double escape quotes
        if (flattened[i].formulatype === 'js') {
          weaved = `(js-eval \\"${weaved}\\")`;
        }
        code.push(weaved);
      });
      return code;
    }

    window.PROV.modal = this.createModal.bind(this);
    window.PROV.ui.modal = this.openModal.bind(this);

    document.addEventListener('click', (ev) => {
      if (!ev || !ev.target) return;
      const {dataset} = ev.target;
      if (!dataset || !dataset.action) return;

      const cellid = dataset.id;
      window.PROV.ui[ev.target.dataset.action](cellid);
    });

    this.driveapi = driveapi({onSignedIn: this.onSignedIn.bind(this), onSignedOut: this.onSignedOut.bind(this)});
    await this.driveapi.load();

    let workbook = await this.tryLoadExample();
    if (!workbook) workbook = await this.tryLoadGD();
    if (!workbook) workbook = this.loadDefaultExample();
    const {sheets, settings={}} = workbook;
    // Save state first, so the user can see the code regardless of execution errors
    this.setState({sheets, settings});
    if (sheets) {
      await this.executeCells(sheets);
    }
  }

  onSignedIn() {
    const google = {signedin: true}
    this.setState({google});
  }

  onSignedOut() {
    const google = {signedin: false};
    this.setState({google});
  }

  onAddWorksheet() {
    let {sheets} = this.state;
    const index = sheets.length;
    const newcell = {...DEFAULT_CELL, sheetId: index};
    newcell.id = cellid(newcell);
    sheets.push([[newcell]]);
    this.setState({sheets, activeSheetId: index});
  }

  onToggleCol(col) {
    // const cols = document.getElementsByClassName('cell-hideable-' + col);
    // for (let elem of cols) {
    //   const state = elem.style.display;
    //   if (state === 'none') elem.style.display = 'block';
    //   else elem.style.display = 'none';
    // }
  }

  async onClear() {
    await this.executeCells(EMPTY_SHEETS);
    localStorage.setItem('provable_notebook', null);
  }

  onExport() {
    export2Sheet(prep2save(this.state.sheets), 'ods');
  }

  onExportGDrive() {
    const workbook = export2raw(prep2save(this.state.sheets));
    uploadFileGD(workbook);
  }

  onImport(e) {
    const file = e.target.files[0];
    const self = this;

    var reader = new FileReader();
    reader.onload = function(e) {
      var data = new Uint8Array(e.target.result);

      const sheets = import2prov(data);
      console.log('sheets', sheets);
      if (sheets) {
        self.setState({sheets});
        saveLocalStorage(sheets, self.state.settings);
      }
    };
    reader.readAsArrayBuffer(file);
  }

  async onImportGdrive() {
    const filebuffer = await this.driveapi.picker();
    const sheets = import2prov(filebuffer);
    console.log('sheets', sheets);
    if (sheets) this.setState({sheets});
  }

  signin() {
    this.driveapi.signin();
  }

  signout() {
    this.driveapi.signout();
  }

  render() {
    const {signedin} = this.state.google;
    const handlers = {
      onChangeCellContent: this.onChangeCellContent.bind(this),
      onDirtyCellChange: this.onDirtyCellChange.bind(this),
      onAddWorksheet: this.onAddWorksheet.bind(this),
      onAddCellAt: this.addCellAt.bind(this),
      onRowOrderChange: this.onRowOrderChange.bind(this),
      onToggleCol: this.onToggleCol.bind(this),
    }

    const modals = this.state.modals.map((item, i) => {
      const closeModal = () => {
        const modals = this.state.modals;
        const mod = modals.find(it => it.id === item.id);
        mod.open = false;
        this.setState({modals});
      }

      return (<Modal
        key={i}
        id={item.id}
        ref={item.ref}
        content={item.content}
        open={item.open}
        close={closeModal.bind(this)}
        width={this.state.modalSize.width}
        height={this.state.modalSize.height}
      />);
    });

    const gdrivetools = [];
    if (signedin) {
      gdrivetools.push(<button className="btnns" key="1" onClick={this.onExportGDrive.bind(this)} style={{position: 'fixed', top: 0, right: 40}}> <ExportBtn /> </button>);
      gdrivetools.push(<button
        key="0"
        className="btnns"
        onClick={this.onImportGdrive.bind(this)}
        style={{position: 'fixed', top: 0, right: 80, zIndex: 1000}}
      > <ImportBtn /> </button>);
    }

    const tools = [];
    tools.push(
      <button key={0} className="btnns" onClick={() => window.open('./PrivacyPolicy.html', '_blank')} style={{position: 'fixed', top: 0, left: 40}}> <InfoBtn /> </button>
    )

    tools.push(<button key={1} className="btnns" onClick={this.onExport.bind(this)} style={{position: 'fixed', top: 0, left: 80}}> <ExportBtn /> </button>);

    tools.push(
      <div key={2}>
        <label for="fileupload" className="custom-file-upload">
          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#000000"><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"></path><path d="M8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"></path></svg>
        </label>
        <input id="fileupload" type="file"/>
      </div>
    );

    return (
      <div className="App" id="app">
        <Workbook sheets={this.state.sheets} handlers={handlers} />
        {modals}

        <div className="tools">
          {tools}
          <button
            className="btnns"
            onClick={signedin ? this.signout.bind(this) : this.signin.bind(this)}
            style={{position: 'fixed', top: 0, right: 0, zIndex: 1000}}
          > <GdriveBtn /> </button>
          {gdrivetools}
        </div>
        <button className="btnns" onClick={this.onClear.bind(this)} style={{position: 'fixed', bottom: 0, right: 0}}> <VersionsBtn /> </button>
      </div>
    );
}
}

export default App;
