import React from 'react';

function ExportBtn () {
    return <svg className="btnns-svg" width="24px" height="24px" viewBox="0 0 24 24" focusable="false" fill="#000000"><path d="M4 15h2v3h12v-3h2v3c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2m11.59-8.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5-1.41-1.41z"></path></svg>
}

function GdriveBtn () {
    return <svg className="btnns-svg" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000" focusable="false"><path d="M19 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM19 20H5V19H19V20ZM19 17H5V4H19V17Z"></path><path d="M13.1215 6H10.8785C10.5514 6 10.271 6.18692 10.0841 6.46729L7.14019 11.6075C7 11.8878 7 12.215 7.14019 12.4953L8.26168 14.4579C8.40187 14.7383 8.72897 14.9252 9.05608 14.9252H15.0374C15.3645 14.9252 15.6449 14.7383 15.8318 14.4579L16.9533 12.4953C17.0935 12.215 17.0935 11.8878 16.9533 11.6075L13.9159 6.46729C13.7757 6.18692 13.4486 6 13.1215 6ZM10.1776 12.0748L12.0467 8.8972L13.8692 12.0748H10.1776Z"></path></svg>
}

// download
function ImportBtn () {
    return <svg className="btnns-svg" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000"><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"></path><path d="M8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"></path></svg>
}

function InfoBtn () {
    return <svg className="btnns-svg" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none"></path><path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path></svg>
}

function VersionsBtn() {
    return <svg version="1.1" className="btnns-svg" x="0" y="0" width="24px" height="24px" viewBox="0 0 24 24" focusable="false" overflow="visible" fill="#000000"><path d="m13,3c-4.97,0 -9,4.03 -9,9l-3,0l3.89,3.89l0.07,0.14l4.04,-4.03l-3,0c0,-3.87 3.13,-7 7,-7s7,3.13 7,7s-3.13,7 -7,7c-1.93,0 -3.68,-0.79 -4.94,-2.06l-1.42,1.42c1.63,1.63 3.87,2.64 6.36,2.64c4.97,0 9,-4.03 9,-9s-4.03,-9 -9,-9z" fill="black" id="svg_1"></path></svg>
}

function RemoveBtn() {
    return <svg className="btnns-svg" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000" focusable="false" class="undefined a-s-fa-Ha-pa"><path d="M0 0h24v24H0z" fill="none"></path><path d="M15 4V3H9v1H4v2h1v13c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V6h1V4h-5zm2 15H7V6h10v13z"></path><path d="M9 8h2v9H9zm4 0h2v9h-2z"></path></svg>
}

export {ExportBtn, GdriveBtn, ImportBtn, InfoBtn, VersionsBtn, RemoveBtn};
