const SHEET_KEY_REGEX = /(@[A-Z]{1}[0-9]{1,})/g   // eslint-disable-line no-useless-escape

const DEFAULT_CELL = {
    id: 'cell_0_0_0',
    sheetId: 0,
    row: 0,
    col: 0,
    formula: "",
    view: "",
    type: ""
}

// A <-> 0
const numberToLetter = ci => String.fromCharCode(65 + ci);
const letterToNumber = letter => letter.charCodeAt(0) - 65;
const lettersToNumber = letters => letters.split('').reduce((accum, letter) => accum + letterToNumber(letter), 0);
const indexes2id = (row, col) => `${numberToLetter(col)}${row + 1}`;
const sheetName = index => String.fromCharCode(97 + index);
const cellid = cell => 'cell_' + cell.sheetId + '_' + cell.col + '_' + cell.row;
const key2cell = key => {
    const [row, col] = id2indexes(key);
    return {row, col};
}
const id2cell = id => {
    const indexes = id.substring(5).split('_').map(v => parseInt(v));
    return {sheetId: indexes[0], col: indexes[1], row: indexes[2]};
}

// A23 -> [0, 23]
// TODO add sheetId as third param
const id2indexes = id => {
    const letters = id.match(/^[A-Z]*/)[0];
    const number = parseInt(id.substring(letters.length)) - 1;
    return [number, lettersToNumber(letters)];
}

const matchCellKeys = (code, callback) => {
    const matches = [...code.matchAll(SHEET_KEY_REGEX)].reverse();
    for (const match of matches) {
        const cell_key = match[0].slice(1);   // @A11
        const replacement = callback(...id2indexes(cell_key));
        code = code.substring(0, match.index) + replacement + code.substring(match.index + match[0].length);
    }
    return code;
}

const recursiveMatchCellKeys = (code, callback) => {
    code = matchCellKeys(code, callback);
    if (code.match(SHEET_KEY_REGEX)) {
        return recursiveMatchCellKeys(code, callback);
    }
    return code;
}

export {
    DEFAULT_CELL,
    numberToLetter,
    indexes2id,
    id2indexes,
    matchCellKeys,
    recursiveMatchCellKeys,
    sheetName,
    cellid,
    key2cell,
    id2cell,
}
