import React from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import './Modal.css';

const Modal = React.forwardRef((props, ref) => {
    const {open, close, content, width, height} = props;

    const Component = content.component;
    const instance = <Component {...content.props} size={{width, height}} parentRef={ref}/>;

    return (
        <ReactModal ref={ref} initWidth={width} initHeight={height}
            className={"provModal"}
            isOpen={open}>
                <button onClick={close} style={{position: 'absolute', top: 0, left: 0}}>X</button>
                <div className="modal_container">
                    {instance}
                </div>
        </ReactModal>
    );
});

export default Modal;
