import React, {useEffect, useState} from 'react';
import YouTube from 'react-youtube';
import './youtube.css';

const instrname = {
    play: 'playVideo',
    pause: 'pauseVideo',
    stop: 'stopVideo',
    seekTo: 'seekTo'
}

function YT(props) {
    const {videoid, actions=[], events=[]} = props;

    const [player, setPlayer] = useState();

    const _onReady = (event) => {
        const _player = event.target;
        _player.pauseVideo();
        setPlayer(_player);
    }

    useEffect(() => {
        if (!player || !actions || !actions.length) return;
        actions.forEach(action => {
            // [<name> ...args]
            player[instrname[action[0]]](...action.slice(1));
        });
    });

    const opts = {
      width: '100%',
      height: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        enablejsapi: 1,
        modestbranding: 1,
      },
    };

    const evs = {};
    events.forEach(event => evs[event[0]] = event[1]);

    return <YouTube containerClassName="yt_wrap" className="yt_component" videoId={videoid} opts={opts} onReady={_onReady} {...events} />;
}

const extension = {
    name: 'youtube',
    formula: `(fn* (cellid videoid) (js-eval (str
        "extensions.youtube("
            (mal2js-str cellid)
            ","
            (mal2js-str videoid)
        ")"
    )))`,
    callback: (cellid, videoid) => {
        return {
            cellid,
            key: cellid + '_youtube',
            type: 'react',
            component: YT,
            props: {videoid}
        };
    }
}

export default extension;
