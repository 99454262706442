import Editor from '../components/editor/Editor';

const extension = {
    name: 'editor',
    formula: `(fn* (cellid language source) (js-eval (str
        "extensions.editor("
        (mal2js-str cellid)
        ","
        (mal2js-str language)
        ","
        (pr-str source)
        ")"
    )))`,
    callback: (cellid, language, source) => {
        if (typeof source !== 'string') {
            try {
                source = JSON.stringify(source);
            } catch(e) {
                source = `typeof source is ${typeof source} must be a string.`;
            }
        }
        return {
            cellid,
            key: cellid + '_editor',
            type: 'react',
            component: Editor,
            props: {
                cellid,
                data: source,
                language,
            }
        }
    }
}

export default extension;
