import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Worksheet from '../worksheet/Worksheet';
import './Workbook.css';
import { sheetName } from '../cell/utils';

function Workbook(props) {
    const { handlers, sheets } = props;

    const tabs = sheets.map((sheet, i) => (
        <Tab key={i}>{sheetName(i)}</Tab>
    ));

    const worksheets = sheets.map((sheet, i) => (
        <TabPanel key={i} style={{height: '100%', width: '100%'}}>
            <Worksheet key={i} id={i} data={sheet} handlers={handlers}/>
        </TabPanel>
    ));

    return (
        <Tabs defaultIndex={0} style={{height: '100%', width: '100%'}}>
            <div id="prov_workbook" style={{height: '100%', width: '100%'}}>{worksheets}</div>
            <TabList style={{position: 'fixed', bottom: '-10px', left: 0}}>
                {tabs}
                <button
                    style={{width: '24px', height: '21px', background: '#efefef', textAlign: 'center'}}
                    onClick={handlers.onAddWorksheet}
                >+</button>
            </TabList>
        </Tabs>
    )
}

export default Workbook;
