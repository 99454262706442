import React, {useEffect, useState, useRef} from 'react';
import { SortablePane, Pane } from 'react-sortable-pane';

const getdirection = (width, height) => width >= height ? 'h' : 'v';
// there is a padding
const getSlicePerc = count => (100 / count) - (count - 1) * 2.5;
const clone = value => JSON.parse(JSON.stringify(value));

function LayoutHorizontal(props) {
    const {children} = props;
    const width = getSlicePerc(children.length);

    const items = children.map((item, i) => {
        return (<Pane className="laypane_h" key={i} resizable={false} defaultSize={{ height: '100%', width: width + '%' }} >
            {item}
        </Pane>);
    });

    return (
        <SortablePane direction="horizontal" margin={20} className="panes" disableEffect={true}>
            {items}
        </SortablePane>
    )
}

function LayoutVertical(props) {
    const {children} = props;
    const height = getSlicePerc(children.length) + '%';

    const items = children.map((item, i) => {
        return (<Pane className="laypane_v" key={i} resizable={false} defaultSize={{ width: '100%', height }} >
            {item}
        </Pane>);
    });

    return (
        <SortablePane direction="vertical" margin={20} className="panes" disableEffect={true}>
            {items}
        </SortablePane>
    )
}

function recursiveLayout(items, size, key, actions, events) {

    const direction = getdirection(size.width, size.height);
    const count = items.length;
    let Lay, newsize;

    if (direction === 'h') {
        Lay = LayoutHorizontal;
        newsize = {width: size.width / count, height: size.height};
    } else {
        Lay = LayoutVertical;
        newsize = {width: size.width, height: size.height / count};
    }

    const itemsr = items.map((item, i) => {
        // Can have multiple actions
        const hasActions = [];
        const hasEvents = [];

        actions.forEach(action => {
            if (action[0][0] === i) {
                // Remove first index from id ; e.g. [0, 1, 2]
                action[0].shift();
                hasActions.push([
                    action[0],           // rest of id ; e.g. [1, 2]
                    ...action.slice(1),  // args
                ])
                // TODO: splice actions
            }
        });
        events.forEach(event => {
            if (event[0][0] === i) {
                // Remove first index from id ; e.g. [0, 1, 2]
                event[0].shift();
                hasEvents.push([
                    event[0],           // rest of id ; e.g. [1, 2]
                    ...event.slice(1),  // args
                ])
                // TODO: splice events
            }
        });

        if (!(item instanceof Array)) {
            const component = window.PROV.utils.getComponent(item);
            const Component = component.component;

            // We don't need the id anymore;
            const actions = hasActions.map(action => action.slice(1));
            const events = hasEvents.map(event => event.slice(1));

            return <Component key={i} {...component.props} actions={actions} events={events} />
        }
        else return recursiveLayout(item, newsize, i, hasActions, hasEvents);
    });
    return <Lay className="lay_wrap" key={key}>{itemsr}</Lay>;
}

function recursiveVisit(items, callb, indexes = []) {
    return items.map((item, i) => {
        const newi = indexes.concat([i]);
        if (!(item instanceof Array)) {
            return callb(item, newi);
        } else return recursiveVisit(item, callb, newi);
    });
}

// Each action step must begin with the correct setup
// E.g. for video: the start second
function Presentation(props) {
    const {items, parentRef, size, instructions={}} = props;
    let currentSize = {...size};
    const {actions=[]} = instructions;

    const [isplaying, setIsPlaying] = useState(false);
    const [actionStep, setActionStep] = useState(0);


    const currentTime = parseInt(actions[actionStep][0]);
    const currentActions = clone(actions[actionStep].slice(1));
    // We need this to be available across component lifetime
    const currentTimeoutId = useRef(null);

    const clearT = () => {
        clearTimeout(currentTimeoutId.current);
        currentTimeoutId.current = null;
    }

    const togglePlay = () => {
        setIsPlaying(!isplaying);
        if (currentTimeoutId.current) clearT();
    }

    useEffect(() => {
        if (!isplaying || actionStep >= (actions.length - 1)) return;
        if (currentTimeoutId.current) clearT();
        currentTimeoutId.current = setTimeout(() => {
            // If user paused presentation, it removes this id and we do not proceed
            if (!currentTimeoutId.current) return;
            setActionStep(actionStep + 1);
        }, currentTime * 1000);
    });

    if (parentRef && parentRef.current) {
        const {width, height} = parentRef.current.state;
        // decrease modal padding
        currentSize = {width, height};
    }

    let acts = currentActions;
    let evs = [];

    if (!isplaying) {
        const indexes = [];
        recursiveVisit(items, (item, inds) => {
            indexes.push(inds);
        });
        acts = indexes.map(inds => [inds, 'pause']);
        evs = indexes.map(ev => [ev, 'onClick', () => {
            setIsPlaying(false);
        }]);
    }

    const layout = recursiveLayout(items, currentSize, null, acts, evs);
    return (
        <div style={{width: '100%', height: '100%'}}>
            <button
                style={{position: 'absolute', top: 0, left: '50px'}}
                onClick={togglePlay}
            >{isplaying ? 'pause' : 'play'}</button>
            {layout}
        </div>
    )
}

const pres = {
    name: 'pres',
    formula: `(fn* (cellid layout instructions) (js-eval (str
        "extensions.pres("
            (mal2js-str cellid)
            ","
            (mal2js-str layout)
            ","
            (mal2js-str instructions)
        ")"
    )))`,
    callback: async (cellid, items=[], instructions=[]) => {
        // actual size provided by the container
        const size ={width: 500, height: 300};
        return {
            cellid,
            key: cellid + '_pres',
            type: 'react',
            component: Presentation,
            props: {items, size, instructions}
        };
    },
    prereq: [
        {name: 'lay', formula: `(fn* (& ls) ls )`},
    ]
}

export default pres;
