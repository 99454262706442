import React from 'react';

function Button(props) {
    const {cellid} = props;
    return <button data-id={cellid} data-action="modal" >run</button>;
}

const extension = {
    name: 'modal',
    formula: `(fn* (cellid component) (js-eval (str
        "extensions.modal("
            (pr-str cellid)
            ","
            (mal2js-str component)
        ")"
    )))`,
    callback: (cellid, component) => {
        window.PROV.modal(cellid, component);
        return {
            cellid,
            key: cellid + '_modal',
            type: 'react',
            component: Button,
            props: {cellid}
        };
    }
}

export default extension;
