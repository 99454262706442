import React from 'react';

function Button(props) {
    const {name, onClick, handlers} = props;

    const _onClick = async () => {
        onClick(handlers);
    }

    return (
        <div>
            <button onClick={_onClick}>{name}</button>
        </div>
    );
}

const extension = {
    name: 'button',
    formula: `(fn* (cellid fname & args) (js-eval (str
        "extensions.button("
        (mal2js-str cellid)
        ","
        (mal2js-str fname)
        ","
        (mal2js-str args)
        ")"
    )))`,
    callback: (cellid, fname, args, oo) => {

        const onClick = async (handlers) => {
            const result = await window[fname](...args);
            const cell = window.PROV.utils.id2cell(cellid);
            cell.dirtyview = result;

            handlers.onDirtyChange(cell);
            return result;
        }
        return {
            cellid,
            key: cellid + '_button',
            type: 'react',
            component: Button,
            props: {
                name: fname,
                onClick,
            }
        }
    }
}

export default extension;
