import { nanoid } from 'nanoid';

const uniqueid = nanoid;

function addScript(code, callback) {
    var s = document.createElement( 'script' );
    s.type = 'text/javascript';
    document.body.appendChild(s);

    try {
        s.innerHTML = code;
    } catch (e) {
        console.log(e);
    }

    const event = new Event('loaded');

    // Listen for the event.
    s.addEventListener('loaded', callback, false);

    // Dispatch the event.
    s.dispatchEvent(event);
}

function addScriptSrc(src, callback) {
    var s = document.createElement( 'script' );
    s.type = 'text/javascript';
    document.body.appendChild(s);
    s.src = src;
    s.onload = callback;
}

async function tryLoadExample(url) {
    const parsedUrl = new URL(url);
    const exampleurl = parsedUrl.searchParams.get('example');
    if (!exampleurl) return;
    let decoded;
    try {
        decoded = decodeURIComponent(exampleurl);
    } catch(e) {console.error(e)};

    const response = await fetch(decoded);

    if (!response.ok) {
        return;
    }
    let filebuuffer = await response.blob();
    filebuuffer = await filebuuffer.arrayBuffer();
    return new Uint8Array(filebuuffer)
}

export {
    addScriptSrc,
    addScript,
    uniqueid,
    tryLoadExample,
}
