import React, {memo} from 'react';
import DEFAULT_CONFIG from './config';

import './Editor.css';
// import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import {UnControlled as CodeMirror} from 'react-codemirror2';
require('codemirror/mode/scheme/scheme');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/commonlisp/commonlisp');
require('codemirror/addon/edit/matchbrackets');
require('codemirror/addon/edit/closebrackets');
require('codemirror/addon/hint/show-hint');
require('codemirror/addon/hint/anyword-hint');

const modemap = {
    sol: 'javascript',
    solidity: 'javascript',
    yul: 'commonlisp',
}

const Editor = memo((props) => {
  let {cellid, data, language, handlers} = props;

  const config = {...DEFAULT_CONFIG};
  config.mode = language ? (modemap[language] || language) : config.mode;

  const onSubmit = (value) => {
    const cell = {
        ...window.PROV.utils.id2cell(cellid),
        dirtyview: value,
    }
    handlers.onDirtyChange(cell);
  };

  return (
    <CodeMirror
        value = {data}
        options={config}
        onChange={(editor, data, value) => {
            // if (props.onChange) props.onChange(value);
        }}
        onBlur={(editor) => {
            const value = editor.getValue();
            if (onSubmit) onSubmit(value);
        }}
        onKeyDown={(editor, event) => {
            // Ctrl/Meta + s -> execute cell
            if (
                (event.ctrlKey === true || event.metaKey === true)
                && event.keyCode === 83
            ) {
                event.stopPropagation();
                event.preventDefault();
                const value = editor.getValue();
                if (onSubmit) onSubmit(value);
            }
        }}
    />
  );
});

export default Editor;
