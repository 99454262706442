/* eslint no-undef: 0 */
/* eslint-disable */
import { addScriptSrc } from '../../utils/utils';
import jszip from 'jszip';

const CLIENT_ID = process.env.REACT_APP_GDRIVE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GDRIVE_API_KEY;
const APP_ID = process.env.REACT_APP_GDRIVE_APP_ID;

const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.install';

const MIME_TYPE_ODS = 'application/vnd.oasis.opendocument.spreadsheet';

// Open With
// {"ids":["19RJnQilpa2gN2-goE3DnS8hMMVXGne0U"],"action":"open","userId":"115493156936173388234"}

// New
// {"action":"create", "folderId":"0ADK06pfg", "userId":"103354693083460731603"}

function getDriveUIState(url) {
    const state = url.searchParams.get('state');
    if (!state) return;
    let decoded;
    try {
        decoded = JSON.parse(decodeURIComponent(state));
    } catch(e) {console.log(e)};
    return decoded;
}

// Url can contain a google drive file id
// or the state parameter, from the Drive UI integration
function tryLoadGD(url) {
    const parsedUrl = new URL(url);
    const state = getDriveUIState(parsedUrl);
    console.log('state', state);
    if (!state || !state.ids) return;
    return getFile({id: state.ids[0], mimeType: MIME_TYPE_ODS});
}

async function getFileFallback(file) {
    const url = `https://drive.google.com/uc?id=${file.id}&export=download&key=${API_KEY}`

    const response = await fetch(url);
    console.log('response', response);
}

// Only for .ods
async function getFile(file) {
    var request = gapi.client.drive.files.get({
      'fileId': file.id,
      'alt': 'media',
      mimeType: file.mimeType,
    });

    let response;
    try {
        response = await request.getPromise();
    } catch(e) {
        console.log(e);
    }
    if (!response) response = await getFileFallback(file);

    if (!response) throw new Error('File not found');

    const zzip = new jszip();
    const gz = await zzip.loadAsync(response.body);
    return gz.generateAsync({
        type: 'uint8array',
        mimeType: 'application/ods',
        compression: 'DEFLATE'
    });
}

async function uploadFileGD(base64file) {
    const boundary = '-------314159265358979323846';
    const delimiter = "\r\n--" + boundary + "\r\n";
    const close_delim = "\r\n--" + boundary + "--";
    const contentType = MIME_TYPE_ODS;

    var metadata = {
        'name': 'factotum_' + new Date().getTime() + '.ods',
        'mimeType': contentType,
    }

    var multipartRequestBody = delimiter +
        'Content-Type: application/json\r\n\r\n' +
        JSON.stringify(metadata) +
        delimiter +
        'Content-Type: ' + contentType + '\r\n' +
        'Content-Transfer-Encoding: base64\r\n' +
        '\r\n' +
        base64file +
        close_delim;

    var request = gapi.client.request({
        'path': '/upload/drive/v3/files',
        'method': 'POST',
        'params': {'uploadType': 'multipart'},
        'headers': {
        'Content-Type': 'multipart/related; boundary="' + boundary + '"',
        },
        'body': multipartRequestBody,
    });

    console.log('request', request);
    const resp = await request.getPromise();
    console.log('resp', resp);
    const filedataReq = gapi.client.drive.files.get({'fileId': resp.result.id, 'fields': ['webViewLink']});
    const filedata = await filedataReq.getPromise();
    console.log('filedata', filedata);
    window.open(filedata.result.webViewLink, '_blank');
}

function driveapi({onSignedIn, onSignedOut}) {

    let signedIn = false; // eslint-disable-line no-unused-vars
    var pickerApiLoaded = false;
    var oauthToken;
    var picker, pickerView;

    const scriptLoad = () => new Promise((resolve, reject) => {
        addScriptSrc('https://apis.google.com/js/api.js', () => {
            resolve();
        }, 'src');

        // For the save to drive button
        addScriptSrc('https://apis.google.com/js/platform.js', () => {}, 'src');
    });

    const clientLoad = () => new Promise ((resolve, reject) => {
        window.gapi.load('client:auth2', resolve);
    });

    const pickerLoad = () =>  new Promise ((resolve, reject) => {
        window.gapi.load('picker', {'callback': resolve});
    });

    function initClient() {
        return new Promise ((resolve, reject) => {
            window.gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES
            }).then(function () {
                // Listen for sign-in state changes.
                window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

                resolve(updateSigninStatus());
            }, function(error) {
                reject(error);
            });
        });
    }

    const load = async () => {
        await scriptLoad();
        await clientLoad();
        const {isSignedIn} = await initClient();
        if (isSignedIn) {
            await pickerLoad();
            pickerApiLoaded = true;
        }
    }

    function updateSigninStatus() {
        // Handle the initial sign-in state.
        const inst = window.gapi.auth2.getAuthInstance();
        const isSignedIn = inst.isSignedIn.get();
        let data;
        if (isSignedIn) {
            const user = inst.currentUser.get();
            data = user.getAuthResponse(true);
            onSignedIn(data);
            signedIn = isSignedIn;
            oauthToken = data.access_token; // picker needs it
        } else {
            onSignedOut();
            signedIn = false;
            oauthToken = null;
        }
        console.log('data', data);
        return {isSignedIn, data};
    }

    function signin() {
        console.log('signin');
        window.gapi.auth2.getAuthInstance().signIn();
        updateSigninStatus();
    }


    function signout() {
        console.log('signout');
        window.gapi.auth2.getAuthInstance().signOut();
        updateSigninStatus();
    }

    // Create and render a Picker object for searching images.
    function createPicker() {
        if (picker) return picker;

        return new Promise((resolve, reject) => {
            // TODO reject

            const pickerCallback = async (data) => {
                if (data.action === google.picker.Action.PICKED) {
                    const doc = data[google.picker.Response.DOCUMENTS][0];
                    const mimeType = doc[google.picker.Document.MIME_TYPE];
                    console.log('doc', doc, mimeType);

                    const response = await getFile(data.docs[0]);
                    resolve(response);
                }
            }

            if (pickerApiLoaded && oauthToken) {
                pickerView = new google.picker.View(google.picker.ViewId.DOCS);
                // view.setMimeTypes("application/octet-stream");
                picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.NAV_HIDDEN)
                    // .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .setAppId(APP_ID)
                    .setOAuthToken(oauthToken)
                    .addView(pickerView)
                    .addView(new google.picker.DocsUploadView())
                    .setDeveloperKey(API_KEY)
                    .setCallback(pickerCallback)
                    .build();
                picker.setVisible(true);
            }
        });
    }

    return {
        load,
        signin,
        signout,
        picker: createPicker,
    }
}

export default driveapi;
export {tryLoadGD, uploadFileGD};
