import {cellid} from '../cell/utils';

function prepCells(sheets) {
  return sheets.map((sheet, si) => {
    return sheet.map((col, ci) => {
      return col.map((cell, ri) => {
        cell.sheetId = si;
        cell.row = ri;
        cell.col = ci;
        cell.id = cellid(cell);
        return cell;
      });
    });
  });
}

async function executeCells (sheets, execallb, MAXITER, initialorder) {
    const flattened = sheets.flat().flat();
    if (!initialorder) initialorder = [...flattened.keys()];
    const [results, execorder, notexecuted] = await _executeCells(sheets, flattened, {}, initialorder, execallb, MAXITER);

    // Update executed cells & leave rest as they where

    let index = 0;
    const items = sheets.map(sheet => {
      return sheet.map(col => {
        return col.map(row => {
          if (results[index]) {
            row.view = results[index].response;
            row.formulatype = results[index].formulatype;
          }
          index += 1;
          return row;
        });
      });
    });
    return [items, execorder, notexecuted];
}

async function _executeCells(sheets, flattened, results, remainingIndexes, execallb, maxiter, execorder=[]) {
    if (remainingIndexes.length === 0) return [results, execorder, []];
    if (maxiter < 1) return [results, execorder, remainingIndexes];
    maxiter -= 1;

    const localorder = [];

    // Execute cells in initial given order
    for (let i = 0; i < remainingIndexes.length; i++) {
      const index = remainingIndexes[i];
      const {response, error, formulatype} = await execallb(flattened[index], sheets);
      results[index] = { response, error, formulatype };
      if (!error) {
        localorder.push(index);
      }
    }

    const _remaining = remainingIndexes.filter(i => !localorder.includes(i));
    execorder = execorder.concat(localorder);

    if (_remaining.length > 0 && remainingIndexes.length > _remaining.length) {
      return await _executeCells(sheets, flattened, results, _remaining, execallb, maxiter, execorder);
    }

    return [results, execorder, _remaining];
}


export {
    prepCells,
    executeCells,
    _executeCells,
}
