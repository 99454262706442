// https://github.com/ethereum/remix-project/blob/1d68ec3b2e5bb4f207c19d5db36401daaf6013d6/libs/remix-solidity/src/compiler/compiler-worker.ts

import * as solc from 'solc/wrapper'
let compileJSON= (input) => { return '' }
const missingInputs = [];

// 'DedicatedWorkerGlobalScope' object (the Worker global scope) is accessible through the self keyword
export default function (self) {
  self.addEventListener('message', (e) => {
    const data = e.data
    switch (data.cmd) {
      case 'loadVersion':
        {
          delete self.Module
          // NOTE: workaround some browsers?
          self.Module = undefined
          compileJSON = null
          //importScripts() method of synchronously imports one or more scripts into the worker's scope
          self.importScripts(data.data)
          const compiler = solc(self.Module)
          compileJSON = (input) => {
            try {
              const missingInputsCallback = (path) => {
                missingInputs.push(path)
                return { 'error': 'Deferred import' }
              }
              return compiler.compile(input, { import: missingInputsCallback })
            } catch (exception) {
              return JSON.stringify({ error: 'Uncaught JavaScript exception:\n' + exception })
            }
          }
          self.postMessage({
            cmd: 'versionLoaded',
            data: compiler.version()
          })
          break
        }

      case 'compile':
        missingInputs.length = 0
        if(data.input && compileJSON) {
          self.postMessage({
            cmd: 'compiled',
            job: data.job,
            data: compileJSON(data.input),
            missingInputs: missingInputs
          })
        }
        break
      default:
        // do nothing
    }
  }, false)
}
