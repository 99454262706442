import React, {useState} from 'react';
import convert from 'react-from-dom';

function Slide(props) {
    const slides = props.data;
    const [index, setIndex] = useState(0);
    const slide = convert(slides[index]);

    const onNext = () => {
        const newi = index === slides.length - 1 ? 0 : (index + 1);
        setIndex(newi);
    }
    const onPrev = () => {
        const newi = index === 0 ? (slides.length - 1) : (index - 1);
        setIndex(newi);
    }

    return (
        <div style={{width: '100%', height: '100%'}} className="center-parent">
            <button
                style={{position: 'absolute', top: 0, left: 50}}
                className="slides-prev-btn"
                onClick={onPrev}
            >{"<"}</button>
            <div style={{width: '100%', height: '100%'}} className="center-stuff">
                {slide}
            </div>
            <button
                style={{position: 'absolute', top: 0, left: 80}}
                className="slides-next-btn"
                onClick={onNext}
            >{">"}</button>
        </div>
    )
}

const extension = {
    name: 'slide',
    formula: `(fn* (cellid & ls)
        (str
            "<button id='"
            cellid
            "_btn' data-id='"
            cellid
            "' data-action='slide' data-argno=1 data-arg0='"
                (map
                    (fn* (item) (encodeURIComponent item))
                    ls
                )
            "'>slide</button>"
        )
    )`,
    callback: async (cellid, [list]) => {
        const slides = list.substring(1, list.length - 1).split(' ').map(item => {
            return decodeURIComponent(item);
        });
        const slide = <Slide data={slides}></Slide>
        window.PROV.modal(cellid, slide);
    }
}

export default extension;
