import React, { Component } from 'react';
import { SortablePane, Pane } from 'react-sortable-pane';
import convert from 'react-from-dom';
import Cell from '../cell/Cell';
import { numberToLetter } from '../cell/utils';

const pin = '<svg viewBox="0 0 16 16" stroke="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" width="16" height="16"><path d="M 8 1 h 3 v 1 l -1 1 v 4 l 2 0.875 V 9 H 9 v 5.125 L 8 15 l -1 -0.875 V 9 H 4 V 7.875 L 6 7 V 3 L 5 2 V 1 Z" /></svg>';


function WorksheetColumn(props) {
  const {data, sheetId, handlers} = props;
  const cells = data.map((item, i) => {
    return <WorksheetCell key={i} data={item} sheetId={sheetId} handlers={handlers} index={data.length} />
  });

  return (
    <SortablePane
      direction="vertical"
      margin={0}
      className="panes"
      disableEffect={true}
      dragHandleClassName="draghandle-cell"
      onOrderChange={handlers.onRowOrderChange}
    >
      {cells}
    </SortablePane>
  );
}

function WorksheetCell(props) {
  const {data, sheetId, handlers, index} = props;
  const onAddCellAt = () => handlers.onAddCellAt(sheetId, data.row, data.col);

  return (
    <Pane
      key={`${data.id}-${index}`}
      className={`prov-row pane${data.id}`}
      resizable={false}
      size={{ width: '100%' }}
    >
      <button onClick={onAddCellAt} className="btn-clear">+</button>
      <Cell
        key={data.index}
        data={data}
        onChange={handlers.onChangeCellContent}
        onDirtyChange={handlers.onDirtyCellChange}
      />
    </Pane>)
}

class Worksheet extends Component {
  render() {
    const { handlers, data, id } = this.props;
    const colw = 100 / data.length + '%';

    const pinbtn = convert(pin);

    const columns = data.map((item, i) => (
      <Pane
        key={`col_${i}-${data.length}`}
        className={`prov-col prov-col-${i}`}
        resizable={false}
        size={{ width: colw, height: '100%' }}
      >
        <div onClick={() => handlers.onToggleCol(i)}>
          <button className="cell-btn draghandle-col">
            {pinbtn}
          </button>
          <span className="colkey">{numberToLetter(i)}</span>
        </div>
          <WorksheetColumn key={i} id={i} data={item} sheetId={id} handlers={handlers} />
      </Pane>
    ));

    return (
        <SortablePane direction="horizontal" margin={0} disableEffect={true} dragHandleClassName="draghandle-col">
            {columns}
        </SortablePane>
    );
  }
}

export default Worksheet;
