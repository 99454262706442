import React, {useState, useEffect, memo} from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import '../components/modal/Modal.css';
import Debugger, {displayVal} from './debugger';
import {
    ewasmjsvm as _ewasmjsvm,
    evmjs as _evmjs,
    nearjs as _nearjs,
} from 'ewasm-jsvm';

const backends = {
    evm: _evmjs(),
    ewasm: _ewasmjsvm(),
    near: _nearjs(),
}

const str2json = (value) => {
    try {
        value = JSON.parse(value);
        return value;
    } catch(e) {
        value = value.replace(/\\"/g, '"');
        try {
            value = JSON.parse(value);
            return value;
        } catch(e) {
            // console.warn(e)
            // return [];
            throw new Error(e);
        }
    }
}

const extension = {
    name: 'debugger',
    formula: `(fn* (cellid backend hexvalue abi) (js-eval (str
        "extensions.debugger("
        (mal2js-str cellid)
        ","
        (mal2js-str backend)
        ","
        (mal2js-str hexvalue)
        ","
        (mal2js-str abi)
        ")"
    )))`,
    callback: async (cellid, backend, hexvalue, abi) => {

        if (typeof abi === 'string') {
            abi = str2json(abi);
        }

        return {
            cellid,
            key: cellid + '_debugger',
            type: 'react',
            component: DebuggerForm,
            props: {
                backend,
                hexvalue,
                abi: abi || [],
            }
        }
    }
}

export default extension;

const DEFAULT_TX_INFO = {
    gasLimit: 1000000,
    gasPrice: 10,
    from: '0x79f379cebbd362c99af2765d1fa541415aa78508',
    value: 0,
}

const DebuggerForm = memo((props) => {
    const {backend, abi, hexvalue} = props;
    const runner = backends[backend];
    const [runtime, setRuntime] = useState(null);

    if (!hexvalue || !abi || abi.length === 0) return <span>Invalid input</span>;

    if (!abi.find(fabi => fabi.type === 'constructor')) {
        abi.push({ name: 'constructor', type: 'constructor', stateMutability: 'nonpayable', inputs: [], outputs: []});
    }

    useEffect(
        () => {
            runner.deploy(hexvalue, abi)({...DEFAULT_TX_INFO}).then(instance => {
                setRuntime(instance);
            });
        },
        [props.hexvalue, props.abi],  // eslint-disable-line react-hooks/exhaustive-deps
    );

    const funcs = abi.filter(v => v.type === 'function' && v.name !== 'constructor').map((fabi, i) => {
        return <FunctionForm key={i} {...fabi} runtime={runtime} />
    });

    return (
        <div>
            {funcs}
        </div>
    )

}, areEqualDebuggerForm);

function areEqualDebuggerForm (prevprops, nextprops) {
    console.log('------------areEqualDebuggerForm--------------');
    if (prevprops.hexvalue !== nextprops.hexvalue) return false;
    if (prevprops.abi.length !== nextprops.abi.length) return false;
    return true;
}

const FunctionForm = memo((props) => {
    const {name, inputs=[], outputs=[], runtime} = props;

    const [modalstate, setModalState] = useState(false);
    const [debuggerdata, setData] = useState({});
    const [errordata, setError] = useState(null);

    const {data, result=[]} = debuggerdata;
    const close = () => setModalState(false)

    const insRefs = inputs.map(v => React.createRef());
    const ins = inputs.map((value, i) => {
        const {name, type} = value;
        return <input ref={insRefs[i]} key={i} type="text" placeholder={name + ':' + type}></input>
    });

    let outs = outputs.map((value, i) => {
        value.value = result[i];
        return <OutputForm key={i} data={value}/>
    });

    let getArgs = () => {
        return insRefs.map(ref => {
            if (!ref.current) return null;
            let value = ref.current.value;
            if (value.slice(0, 2) === '0x') return value;
            try {
                value = parseInt(value);
            } catch(e) {}
            return value;
        });
    }

    const modal = <DebuggerModal open={modalstate} close={close} data={data} result={result} args={getArgs()} />

    const run = async () => {
        if (!runtime) return;

        let args = getArgs();
        let result;

        try {
            result = await runtime[name](...args, {...DEFAULT_TX_INFO});
            if (!(result instanceof Array)) result = [result];
            setError(null);
        } catch(e) {
            setError(e);
            console.log(e);
            outs = <span>{e.message}</span>
        }

        setData({result, data: runtime.logs});
        setModalState(true);
    }

    return (
        <div style={{display: 'flex'}}>
            <button style={{marginRight: '10px', width: '150px'}} onClick={run}>{name}</button>
            {ins}
            {errordata ? errordata.message : outs}
            {modal}
        </div>
    )
});
// , (prevprops, nextprops) => {
//     if (prevprops.name !== nextprops.name) return false;
//     if (JSON.stringify(prevprops.input) !== JSON.stringify(nextprops.input)) return false;
//     if (JSON.stringify(prevprops.outputs) !== JSON.stringify(nextprops.outputs)) return false;
//     return true;
// });

const DebuggerModal = memo((props) => {
    const {open, close, data, result, args} = props;

    const content = data && data.length > 0 ? <Debugger data={data} result={result} args={args} /> : <span>No data</span>

    return <Modal open={open} close={close} content={content} />
})

function OutputForm(props) {
    const {name, type, value} = props.data;
    return (<span>{displayVal(value)} ({name}: {type})</span>)
}


function Modal(props) {
    const {open, close, content} = props;
    const width = 1200;
    const height = 400;

    return (
        <ReactModal initWidth={width} initHeight={height}
            className={"provModal"}
            isOpen={open}>
                <button onClick={close} style={{position: 'absolute', top: 0, left: 0}}>X</button>
                <div className="modal_container">
                    {content}
                </div>
        </ReactModal>
    );
}

