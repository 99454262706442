import React, { useState, memo } from 'react';
import Inspector, { chromeDark } from 'react-inspector';
import './Cell.css';

// import 'codemirror/lib/codemirror.css';
import './codemirror.css';
import 'codemirror/theme/material.css';
import {UnControlled as CodeMirror} from 'react-codemirror2';
require('codemirror/mode/scheme/scheme');
require('codemirror/mode/javascript/javascript');
require('codemirror/addon/edit/matchbrackets');
require('codemirror/addon/edit/closebrackets');
require('codemirror/addon/hint/show-hint');
require('codemirror/addon/hint/anyword-hint');

const handleReact = (response, handlers) => {
  if (typeof response !== 'object' || response.type !== 'react') return null;
  const comp = window.PROV.utils.getComponent(response);

  // localStorage values are stringified
  if (!comp) return null;
  // memoed components can be objects
  if (typeof comp.component !== 'function' && typeof comp.component !== 'object') return null;

  // TODO give width & height
  const Component = comp.component;
  return <Component {...comp.props} handlers={handlers} />;
}

function Cell (props) {
  const [cursor, setCursor] = useState({line: 0, ch: 0});
  const [livepreview, setLivePreview] = useState(true);

  const toggleLivePreview = (status) => {
    const value = typeof status === 'boolean' ? status : !livepreview;
    setLivePreview(value);
  }

  return (
    <CellInternalMemoed onCursorChange={setCursor} {...props} cursor={cursor} livepreview={livepreview} toggleLivePreview={toggleLivePreview}/>
  )
}

const shallowEqual = (obj1, obj2) => {
  return !(Object.keys(obj1).some(key => obj1[key] !== obj2[key]));
}

function areEqual(prevProps, nextProps) {
  if (prevProps.cursor.line !== nextProps.cursor.line) return false;
  if (prevProps.cursor.ch !== nextProps.cursor.ch) return false;
  if (prevProps.livepreview !== nextProps.livepreview) return false;
  if (!shallowEqual(prevProps.data, nextProps.data)) return false;
  return true;
}

function CellInternal (props) {
  const {data, cursor, livepreview, onCursorChange, toggleLivePreview, onChange, onDirtyChange} = props;
  let output;

  if (!output && data.view) output = handleReact(data.view, {onChange, onDirtyChange});

  console.log('CellInternal', data, output);

  let item;
  if (output) {
      item = output;
  } else if (!data.formula) {
    item = <span></span>;
  }
  else {
      item = <Inspector theme={{...chromeDark, ...({
          BASE_FONT_SIZE: '14px',
          BASE_LINE_HEIGHT: 1.5,
          TREENODE_FONT_SIZE: '14px',
          TREENODE_LINE_HEIGHT: 1.5,
          BASE_BACKGROUND_COLOR: '#222832',
      })}}  data={data.view} />
  }

  const menubtnStyle = livepreview ? "cell-btn" : "cell-btn-active";

  return (
    <div className="Cell">
      <div className="cell-row>">
        <div className="menu cell-menu">
          <button onClick={() => toggleLivePreview(!livepreview)} className={ menubtnStyle + " draghandle-cell"}>
            <span className="cellkey draghandle-cell">{data.row + 1}</span>
          </button>

        </div>

        <div className="output" id={data.id + '_output' } style={{whiteSpace: 'pre-line'}} >{item}</div>
      </div>
      <div className="cell-row">
        <div className={`input cell-row-input`} >
          <CodeMirror
            value = {data.formula}
            cursor={cursor}
            options={{
              mode: 'scheme',
              theme: 'material',
              tabSize: 2,
              lineNumbers: false,
              lineWrapping: true,
              matchBrackets: true,
              autoCloseBrackets: true,
              hint: true,
              viewportMargin: Infinity,
              autoScroll: true,
              //foldGutter: true
            }}
            onChange={(editor, data, value) => {
                onCursorChange(editor.getCursor());
                if (livepreview && value !== props.data.formula) {
                    props.onChange({
                      ...props.data,
                      formula: value,
                    }, false)
                }
            }}
            onBlur={(editor) => {
              const value = editor.getValue();
              toggleLivePreview(true);
              // || !value - make sure we can remove cells if no user input
              if (value !== props.data.formula || !value) {
                  // reenable livepreview
                  props.onChange({
                    ...props.data,
                    formula: value,
                  }, true)
              }
            }}
            onKeyDown={(editor, event) => {
              // Ctrl/Meta + s -> execute cell
              if (
                (event.ctrlKey === true || event.metaKey === true)
                && event.keyCode === 83
              ) {
                event.stopPropagation();
                event.preventDefault();
                const value = editor.getValue();
                const data = {...props.data, formula: value};
                props.onChange(data, false);
                return false;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

const CellInternalMemoed = memo(CellInternal, areEqual);

export default Cell;
