import React, {useState} from 'react';
import {utils} from 'ewasm-jsvm';

const {strip0x, uint8ArrayToHex} = utils;

const listStyle = (headerheight=30) => { return {
    overflowY: 'auto',
    height: `calc(100% - ${headerheight}px)`,
    width: '100%',
}}

const pStyle = {
    marginTop: '10px',
    marginBottom: '10px',
}

function Debugger(props) {
    let {result, data=[], args=[]} = props;
    // name, input, output, memory, logs, context, contract
    const [opindex, setOpindex] = useState(0);

    if (data.length === 0) return <div><p style={pStyle}>Output: {displayVal(result)}</p></div>

    const opcodes = data.map(log => log.name);
    const count = opcodes.length - 1;
    const onOpindexChange = value => setOpindex(value < 0 ? 0 : (value > count ? count : value));
    const input = data[opindex].input;
    const output = data[opindex].output;

    // todo: know max used memory size
    const memoryhex = strip0x(uint8ArrayToHex(new Uint8Array(data[opindex].memory.slice(0, 100 * 64))));
    const memory = prepMemory(memoryhex);

    const storage = data[opindex].contract.storage || {};
    const logs = data[opindex].logs;
    const stack = prepStack(data[opindex].stack);

    return (
        <div style={{display: 'flex', height: '100%'}}>
            <div style={{width: '150px', minWidth: '150px', marginRight: '10px'}}>
                <OpcodesView opcodes={opcodes} selected={opindex} onClick={onOpindexChange} />
                <IOView name={'input'} data={input} styles={{height: '20%'}} onClick={() => {}} />
                <IOView name={'output'} data={output} styles={{height: '20%'}} onClick={() => {}} />
            </div>
            <div style={{marginRight: '10px', height: '100%', width: '51%'}}>
                <div style={{height: '20%', overflowY: 'auto'}}>
                    <IOView name={''} data={args} onClick={() => {}} />
                    <IOView name={'final output'} data={result} onClick={() => {}} />
                </div>
                <StackView slots={stack} onClick={() => {}} />
                <MemoryView slots={memory} onClick={() => {}} />
            </div>
            <div style={{height: '100%', width: '49%'}}>
                <StorageView storage={storage} onClick={() => {}} />
                <LogsView data={logs} onClick={() => {}} />
            </div>
        </div>
    );
}

function OpcodesView(props) {
    const {opcodes, selected, onClick} = props;
    const views = opcodes.map((opcode, i) => {
        const _onClick = () => onClick(i);
        const style = {};
        style.backgroundColor = selected === i ? 'rgba(76, 94, 109, 1)' : '#222832';
        style.color = '#efefef';
        style.fontFamily = 'monospace';
        style.cursor = 'pointer';
        return <OpcodeView style={style} key={i} name={opcode} onClick={_onClick} />
    });
    return (<div style={{height: '60%'}}>
        <div>
            <button onClick={() => onClick(selected - 1)}>{'<'}</button>
            <button onClick={() => onClick(selected + 1)}>{'>'}</button>
        </div>
        <div style={listStyle(20)}>
            {views}
        </div>

    </div>);
}

function OpcodeView(props) {
    const {name, style, onClick} = props;
    return (<div style={{...style, fontFamily: 'monospace'}} onClick={onClick}>{name}</div>);
}

function IOView(props) {
    const {name, onClick, styles} = props;
    let {data} = props;
    if (!(data instanceof Array)) data = [data];

    const views = data.map((arg, i) => {
        const _onClick = (...args) => onClick(...args, arg, i);
        return (<div key={i} style={{fontFamily: 'monospace', overflowWrap: 'break-word'}} onClick={_onClick}>{displayVal(arg)}</div>);
    });

    const nameview = name ? <p style={pStyle}>{name}</p> : <span></span>

    return (<div style={styles}>
        {nameview}
        <div style={listStyle()}>
            {views}
        </div>
    </div>);
}

function MemoryView(props) {
    const {slots, onClick} = props;
    const views = slots.map((slot, i) => {
        const _onClick = (...args) => onClick(...args, slot, i);
        return <MemorySlotView key={i} slot={slot} onClick={_onClick}/>
    });
    return (<div style={{height: '40%'}}>
        <p style={pStyle}>memory</p>
        <div style={listStyle()}>
            {views}
        </div>
    </div>);
}

function MemorySlotView(props) {
    const {slot, style={}} = props;
    return (<div style={{...style, fontFamily: 'monospace'}}>{slot.key} {slot.value}</div>);
}

function StackView(props) {
    const {slots, onClick} = props;
    const views = slots.map((slot, i) => {
        const _onClick = (...args) => onClick(...args, slot, i);
        return <StackSlotView key={i} _key={i} slot={slot} onClick={_onClick}/>
    });
    return (<div style={{height: '40%'}}>
        <p style={pStyle}>stack/registers</p>
        <div style={listStyle()}>
            {views}
        </div>
    </div>);
}

function StackSlotView(props) {
    const {slot, _key, style={}} = props;
    const nos = slot.split('');
    let decoded = [...new Array(nos.length / 2).keys()]
        .map(i => parseInt(nos.slice(i*2, i*2+2).join(''), 16))
        .map(v => String.fromCharCode(v))
        .join('');

    return (
        <div style={{...style, fontFamily: 'monospace'}}>
            <span>{_key}:</span>
            <span style={{paddingLeft: '8px'}}>{slot.length / 2}</span>
            <span style={{paddingLeft: '8px'}}>{slot}</span>
            <span style={{paddingLeft: '8px'}}>|</span>
            <span style={{paddingLeft: '8px'}}>'{decoded}'</span>
        </div>
    );
}

function StorageView(props) {
    const {storage, onClick} = props;
    const views = Object.keys(storage).map((key, i) => {
        const _onClick = (...args) => onClick(...args, key, i);
        return <StorageSlotView key={i} _key={key} value={storage[key]} onClick={_onClick}/>
    });
    return (<div style={{height: '50%'}}>
        <p style={pStyle}>storage</p>
        <div style={listStyle()}>
            {views}
        </div>
    </div>);
}

function StorageSlotView(props) {
    const {_key, value, style={}} = props;
    const viewvalue = strip0x(uint8ArrayToHex(value));
    return (
        <div style={{...style, fontFamily: 'monospace'}}>
            <span style={{display: 'block'}}>{strip0x(_key)}:</span>
            <span style={{paddingLeft: '8px'}}>{viewvalue}</span>
        </div>
    );
}

// function StorageSlotView(props) {
//     const {_key, value, style={}} = props;
//     const viewvalue = value;
//     const decodedkey = uint8ToString(_key.split(','));
//     const decodedvalue = uint8ToString(value);
//     return (
//         <div style={{...style, fontFamily: 'monospace'}}>
//             <span style={{}}>{_key}:</span>
//             <span style={{paddingLeft: '8px'}}>{viewvalue}</span>
//             <span style={{paddingLeft: '8px'}}>|</span>
//             <span style={{paddingLeft: '8px'}}>"{decodedkey}":</span>
//             <span style={{}}>"{decodedvalue}"</span>
//         </div>
//     );
// }

function LogView(props) {
    const {data, style={}} = props;
    data.decoded = [...data.data].map(v => String.fromCharCode(v)).join('');
    return (<div style={{...style, fontFamily: 'monospace'}}>{'> ' + displayVal(data)}</div>);
}

function LogsView(props) {
    const {data} = props;
    const views = data.map((log, i) => {
        return <LogView key={i} data={log} />
    });
    return (<div style={{height: '50%'}}>
        <p style={pStyle}>logs</p>
        <div style={listStyle()}>
            {views}
        </div>
    </div>);
}

// function uint8ToString (value) {
//     return [...value].map(v => String.fromCharCode(v));
// }

function slicePieces (str, slotsize) {
    return [...new Array(Math.ceil(str.length / slotsize)).keys()].map(i => str.slice(i * slotsize, (i + 1) * slotsize));
}

function prepMemory (mem) {
    return slicePieces(mem, 64).map((value, i) => {
        const memkey = (i * 32).toString().padStart(4, '0');
        return {value: value, key: memkey}
    }).filter(val => val.value !== ''.padStart(64, '0'));
}

// [Uint8Array, ... ]
function prepStack (stack) {
    return stack.map(value => {
        return [...value].map(val => val.toString(16).padStart(2, '0')).join('')
    }).reverse();
}

function displayVal (value) {
    if (typeof value === 'undefined') return 'undefined';
    if (value === null) return '';
    if (value instanceof WebAssembly.Global) return value.value;
    if (value instanceof Uint8Array) return uint8ArrayToHex(value);
    if (value instanceof Object && value.words) return value.toString();
    if (value instanceof Object && value.__hex) return value.toString();
    if (value instanceof Array) return '[' + value.map(displayVal).join('\n') + ']';
    if (value instanceof Object) return '{' + displayVal(Object.keys(value).map(key => key + ': ' + displayVal(value[key])) ) + '}';
    return value.toString();
}

export default Debugger;
export {displayVal};
