import XLSX from 'xlsx';
import { indexes2id, numberToLetter, sheetName, cellid } from '../components/cell/utils';

// https://github.com/SheetJS/sheetjs#cell-object
const DEFAULT_CELL_XLSX = {  // eslint-disable-line no-unused-vars
    v: null, // raw value
    w: '',   // formatted tex
    t: 's',  // b Boolean, e Error, n Number, d Date, s Text, z Stub
    f: null,   // formula,
    F: null,   // range of enclosing array if formula is array formula
    r: null,   // rich text encoding
    h: null,   // HTML rendering of the rich text
    c: null,   // comments associated with the cell
    z: null,   // number format string associated with the cell
    l: null,   // cell hyperlink object (.Target holds link, .Tooltip is tooltip)
    s: null,   // the style/theme of the cell
}

const TYPEMAP = {
    'boolean': 'b',
    'number': 'n',
    'string': 's',
}

function vtype(v) {
    const tt = typeof v;
    if (tt === 'object') return {v: JSON.stringify(v), t: 's'};
    if (tt === 'function') return {v: 'function', t: 's'};
    if (TYPEMAP[tt]) return {v, t: TYPEMAP[tt]};
    if (v instanceof Error) return {v, t: 'e'};
    if (v instanceof Date) return {v, t: 'd'};
    if (typeof v === 'undefined') return {v: '', t: 's'};

    throw new Error('Type not found: ' + v);
}

// TODO v vs w
const KEYMAP_P2S = {
    formula: 'f',
    view: 'v',
    type: 't',
}
const KEYMAP_S2P = {}
Object.keys(KEYMAP_P2S).forEach(k => KEYMAP_S2P[KEYMAP_P2S[k]] = k);

function changeKeys(obj, keymap) {
    const newobj = {};
    Object.keys(obj).forEach(k => {
        if (keymap[k]) {
            newobj[keymap[k]] = obj[k];
        }
    });
    return newobj;
}

function prov2aoa(sheet) { // eslint-disable-line no-unused-vars
    // [col [row {}] [] [] ]
    console.log('prov2aoa', sheet);
    let aoa = [];

    sheet.forEach((col, ci) => {
      const addRows = col.length - aoa.length;
      if (addRows > 0) {
        aoa = aoa.concat([...(new Array(addRows)).keys()].map(key => []));
      }
      col.forEach((row, ri) => {
        let v = row.view;
        if (typeof row.view === 'object') v = JSON.stringify(row.view);
        if (typeof row.view === 'function') v = 'function'; // .toString()

        aoa[ri][ci] = v;
      });
    });

    return [
      [ "A", "B", "C" ],
      ...aoa,
    ]
}

function prov2sheetCell(cell) {
    const newcell = changeKeys(cell, KEYMAP_P2S);
    if (!newcell.t) {
        const {t, v} = vtype(cell.view);
        newcell.t = t;
        newcell.v = v;
    }
    return newcell;
}

function sheet2provCell(cell) {
    return changeKeys(cell, KEYMAP_S2P);
}
window.XLSX = XLSX;
function sheet2prov(sheet, sheetId) {
    // { A1: {}, .. }
    const psheet = [];
    Object.keys(sheet).filter(value => value.charAt(0) !== '!').forEach(key => {
        const {c, r} = XLSX.utils.decode_cell(key);
        if (!psheet[c]) psheet[c] = [];
        psheet[c][r] = sheet2provCell(sheet[key]);
        psheet[c][r].row = r;
        psheet[c][r].col = c;
        psheet[c][r].sheetId = sheetId;
        psheet[c][r].id = cellid(psheet[c][r]);
    });
    return psheet;
}

function prov2sheet(sheet) {
    // [[ {} ], [ {} ]] - col first
    // { A1: {v , t, w}, A2: {v, t, w}, }

    const newsheet = {};
    let maxrow = 0;
    sheet.forEach(col => {
        col.forEach(cell => {
            newsheet[indexes2id(cell.row, cell.col)] = prov2sheetCell(cell);
        });
        maxrow = Math.max(maxrow, col.length);
    });
    newsheet['!ref'] = 'A1:' + numberToLetter(sheet.length - 1) + maxrow.toString();
    console.log('prov2sheet', newsheet);
    return newsheet;
}

const prov2workbook = sheets => {
    /* build new workbook */
    const wb = XLSX.utils.book_new();

    sheets.forEach((psheet, i) => {
        const ws = prov2sheet(psheet);
        // const ws = XLSX.utils.aoa_to_sheet(prov2aoa(psheet));
        XLSX.utils.book_append_sheet(wb, ws, sheetName(i));
    });
    console.log('wb', wb);
    return wb;
}

const export2raw = (sheets, opts={}) => {
    const workbook = prov2workbook(sheets);
    var wopts = { bookType:'ods', bookSST:false, type:'base64', ...opts };
    var wbout = XLSX.write(workbook, wopts);
    return wbout;
}

const export2Sheet = (sheets, extension = 'ods') => {
    const wb = prov2workbook(sheets);

	/* write file */
    const file = "sheetjsw." + extension;
    try {
      // XLSX.writeFileAsync(file, wb, null, (file) => {
      //     alert("exportFile success", "Exported to " + file);
      // })
      XLSX.writeFile(wb, file, {bookType: extension});
    } catch(err) {
      console.log('err', err)
      alert("exportFile Error", "Error " + err.message);
    }
}

const import2prov = (data, opts={}) => {
    let workbook;
    try {
        console.log('data', data);
        workbook = XLSX.read(data, {type: 'array', ...opts});
        console.log('workbook', workbook);
        return workbook.SheetNames.map((name, i) => {
            return sheet2prov(workbook.Sheets[name], i);
        });
    } catch(e) {
        console.error(e);
    }
    return;
}

export {
    sheet2prov,
    prov2sheet,
    export2Sheet,
    import2prov,
    export2raw,
}
