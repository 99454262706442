const config = {
    mode: 'commonlisp',
    theme: 'material',
    tabSize: 2,
    lineNumbers: false,
    lineWrapping: true,
    matchBrackets: true,
    autoCloseBrackets: true,
    hint: true,
    viewportMargin: Infinity,
    autoScroll: true,
    //foldGutter: true
}

export default config;
