import convert from 'react-from-dom';

function HtmlComponent(props) {
    const {source} = props;

    return convert(source);
}

const extension = {
    name: 'html',
    formula: `(fn* (cellid source) (js-eval (str
        "extensions.html("
            (pr-str cellid)
            ","
            (pr-str source)
        ")"
    )))`,
    callback: (cellid, source) => {
        return {
            cellid,
            key: cellid + '_html',
            type: 'react',
            component: HtmlComponent,
            props: {source}
        };
    }
}

export default extension;
