import React from 'react';
import { latexify } from './katex';
import htmlextension from './html';
const marked = require("marked");

function Markdown(props) {
    const {source} = props;

    const katexify = latexify(source);

    const html = '<div style="width: 100%; height: 100%;" >' + katexify.map(item => {
        if (item.html) return item.html;

        return marked(item.text);
    }).join('') + '</div>';

    const component = htmlextension.callback(html);
    const Instance = component.component;
    return <Instance {...component.props}/>;
}

const extension = {
    name: 'md',
    formula: `(fn* (cellid str1) (js-eval (str
        "extensions.md("
            (pr-str cellid)
            ","
            (pr-str str1)
        ")"
    )))`,
    callback: (cellid, str) => {
        return {
            cellid,
            key: cellid + '_md',
            type: 'react',
            component: Markdown,
            props: {source: str}
        };
    }
}

export default extension;

