import {Compiler} from './compiler';

const DEFAULT_COMPILER = "https://solc-bin.ethereum.org/bin/soljson-latest.js";
window.PROV.packages.solc = {};

async function compile (cellid, source='', config={}, callback) {
    return new Promise((resolve, reject) => {
        let cache = window.PROV.packages.solc[cellid] || {};

        if (cache.reject) cache.reject('Compilation restarted');

        function finallyCompile() {
            Object.keys(config).forEach(key => cache.compiler.set(key, config[key]));
            cache.compiler.compile({ 'contract': {content: source } }, 'contract');
        }

        const handleEvent = (name, args) => {
            console.log('handleEvent', name, args);
            switch(name) {
                case 'loadingCompiler':
                    if (callback) callback('compiler loading ...');
                    break;
                case 'compilerLoaded':
                    if (callback) callback('compiler loaded');
                    finallyCompile();
                    break;
                case 'compilationStarted':
                    if (callback) callback('compiler compiling ...');
                    break;
                case 'compilationFinished':
                    const [success, data, sources] = args;
                    console.log('----compilationFinished', success, data, sources);
                    if (!success) {
                        cache.reject(data);
                    }
                    else {
                        cache.resolve(data);
                    }
                    if (callback) callback(data);
                    break;
                default:
                    console.log('handleEvent not handled', name, args);
                    return;
            }
        }

        cache.resolve = resolve;
        cache.reject = reject;

        if (!cache || !cache.compiler) {
            const compiler = new Compiler(handleEvent);
            cache.compiler = compiler;
            cache.compiler.loadVersion(true, DEFAULT_COMPILER);
            window.PROV.packages.solc[cellid] = cache;
        }
        else {
            window.PROV.packages.solc[cellid] = cache;
            finallyCompile();
        }
    });
}

const extension = {
    name: 'solc',
    formula: `(fn* (cellid source target) (js-eval (str
        "extensions.solc("
        (pr-str cellid)
        ","
        (pr-str source)
        ","
        (pr-str target)
        ")"
    )))`,
    callback: (cellid, source, target) => {
        if (!source || typeof source !== 'string') return;
        const language = source.includes('pragma solidity') ? 'Solidity' : (source.includes('code {') ? 'Yul' : null);
        if (!language) return;
        const config = {
            language,
            outputSelection: target,
        }
        return compile(cellid, source, config);
    }
}

export default extension;
