import React from 'react';

function Button(props) {
    const {tocopy} = props;
    const ref = React.createRef();
    let source = typeof tocopy === 'string' ? tocopy : JSON.stringify(tocopy);

    const onClick = () => {
        const input = document.createElement("input");
        input.value = source;
        ref.current.append(input);

        /* Select the text field */
        input.select();
        input.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
        input.remove();
    }
    return (<div ref={ref}>
        <button onClick={onClick} >copy</button>
    </div>
    )
}

const extension = {
    name: 'clipboard',
    formula: `(fn* (cellid tocopy) (js-eval (str
        "extensions.clipboard("
        (mal2js-str cellid)
        ","
        (mal2js-str tocopy)
        ")"
    )))`,
    callback: (cellid, tocopy) => {
        return {
            cellid,
            key: cellid + '_clipboard',
            type: 'react',
            component: Button,
            props: {cellid, tocopy},
        }
    }
}

export default extension;
