import Form from "@rjsf/core";

const extension = {
    name: 'ui_form',
    formula: `(fn* (cellid schema obj) (js-eval (str
        "extensions.ui_form("
        (mal2js-str cellid)
        ","
        (mal2js-str schema)
        ","
        (mal2js-str obj)
        ")"
    )))`,
    callback: (cellid, schema, obj) => {
        return {
            cellid,
            type: 'react',
            component: Form,
            props: {
                schema,
                onChange: (v) => console.log('onChange', v),
                onSubmit: (v) => console.log('onSubmit', v),
                onError: (v) => console.log('onError', v),
            }
        }
    }
}

export default extension;
