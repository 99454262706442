import React, {memo, useState} from 'react';
import Form from '@rjsf/core';
import abi2schema from 'solidity-json-schema';

const RECEIPT_TYPE = {
    name: 'receipt',
    type: 'tuple',
    components: [
      // {
      //   name: 'etherscan',
      //   type: 'string',
      // },
      {
        name: 'transactionHash',
        type: 'string',
      },
      {
        name: 'blockHash',
        type: 'string',
      },
      {
        name: 'blockNumber',
        type: 'uint256',
      },
      {
        name: 'confirmations',
        type: 'uint256',
      },
    ]
}

function AbiFormFunction(props) {
    const {abi, contractInstance} = props;

    const [output, setOutput] = useState([]);
    const [error, setError] = useState(null);

    if (!abi.outputs.length) abi.outputs = RECEIPT_TYPE.components;

    const schema = abi2schema([abi]);
    const schemaInput = {
        title: abi.name,
        type: 'object',
        properties: {},
    }
    if (abi.inputs.length > 0) {
        schemaInput.properties.inputs = JSON.parse(JSON.stringify(schema.properties[abi.name].properties.inputs));
    }
    const schemaOutput = {
        title: 'output',
        type: 'object',
        properties: {},
    }
    abi.outputs.forEach((out, i) => {
        schemaOutput.properties[out.name] = schema.properties[abi.name].properties.outputs.items[i];
        delete schemaOutput.properties[out.name].description;
    })
    schemaOutput.readOnly = true;

    const onSubmit = async (v) => {
        const args = v.formData.inputs || [];
        let formData = {};
        let result = await contractInstance[abi.name](...args).catch(e => {
            setError(e);
        });

        console.log('result', result);
        if (typeof result === 'object' && result.wait) {
            result = await result.wait();
            formData = result;
        } else {
            if (!(result instanceof Array)) result = [result];
            abi.outputs.forEach((out, i) => {
                formData[out.name] = result[i];
            })
        }
        setOutput(formData);
    }
    const onError = (v) => console.log(v);

    return (
        <div>
            <Form
                schema={schemaInput}
                onSubmit={onSubmit}
                onError={onError}
            />
            <Form
                schema={schemaOutput}
                formData={output}
                children={true}
            />
            {error ? <span>{error.message}</span> : <span></span>}
        </div>
    )
}

function AbiForm(props) {
    const {abi, contractInstance} = props;

    const functions = abi.filter(fabi => fabi.type === 'function')
        .map((fabi, i) => {
            return <AbiFormFunction key={i} abi={fabi} contractInstance={contractInstance} />
        });

    const style = {
        overflowY: 'auto',
        width: '100%',
        height: '100%',
    }

    return (
        <div style={style}>{functions}</div>
    )
}

const extension = {
    name: 'abiform',
    formula: `(fn* (cellid backend contractKey abi address) (js-eval (str
        "extensions.abiform("
        (pr-str cellid)
        ","
        (pr-str backend)
        ","
        (pr-str contractKey)
        ","
        (mal2js-str abi)
        ","
        (pr-str address)
        ")"
    )))`,
    callback: (cellId, backend, contractKey, abi, address) => {
        const {id2indexes, cellid} = window.PROV.utils;
        const [ri, ci] = id2indexes(contractKey);
        const currentSheet = parseInt(cellId.substring(5).split('_')[0]);
        const id = cellid({sheetId: currentSheet, col: ci, row: ri});
        const contractInstance = window.PROV.mal.load(id);
        return {
            cellid,
            key: cellId + '_abiform',
            type: 'react',
            component: memo(AbiForm),
            props: {
                abi,
                contractInstance,
            }
        }
    }
}

export default extension;
